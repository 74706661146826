
/**
 * 
 * 文件上传
 * 
 * */

import axios from 'axios'
import settings from './settings'
import store from '@/store'
import Responsibility from "./filter/api.filter";

// 请求配置对象
let config = {
    headers: {
        'Content-Type': 'multipart/form-data',
        'Client-Type': 50, //  50  后台
        'Session-Id': '',
    },
}

let base_url = settings.config.base_url

// 解决跨域使用
if (process.env.NODE_ENV === 'development') {
    base_url = '/manageApi'
}

let instance = axios.create({
    baseURL: base_url,
    headers: {
        'Client-Type': 50, // 50  后台
        'Content-Type': 'multipart/form-data',
    },
    withCredentials: true,
})
/* 接口请求处理一下生产环境问题 */
instance.interceptors.request.use(
    (config) => {
        Responsibility(config);
        return config
    },
    (err) => {
        //请求失败
        console.error('请求失败')
        return Promise.reject(err)
    }
)

const uploadFile = (path = '', params = {}) => {
    path = `${path}`
    // 处理请求头， 添加session-id  shop-id
    config.headers['Session-Id'] =store.state?.account?.sessionId

    return new Promise((resolve, reject) => {
        instance
            .post(path, params, config)
            .then((result) => {
                resolve(result.data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

export { uploadFile }
