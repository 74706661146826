
let afterLocation = item => ({
    params: [{
        validator: (rule, value, callback) => {

            let { noticestyle, noticeimg } = item.params
            if (noticestyle == 'custom' && !noticeimg) { //选择分类
                callback(new Error('请选择公告图片'))
            } else {
                callback()
            }
        },
        trigger: 'blur'
    }],
})
export default (item) => ({
    afterLocation: afterLocation(item), //定位后的校验
    beforeLocation(data) { //定位前的校验
        let { noticestyle, noticeimg } = data.params
        if (noticestyle == 'custom' && !noticeimg) { //选择分类
            return new Error('请选择自定义图片')
        }
    },

})