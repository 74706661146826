

let afterLocation = item=>({
    data: [{
        validator: (rule, value, callback) => {
            if (!item.data.length) {
                callback(new Error('请添加选项'));
            } else {
                callback();
            }
        }
    }],
})
export default item => ({
    afterLocation:afterLocation(item), //定位后的校验
    beforeLocation(data) { //定位前的校验
        if (data) {
            if (!data.data.length) {
                return new Error('请添加选项')
            } else if(data.data.some(v=>!v.linkurl)) {
                return new Error('请选择页面')
            }
        }
    },
    
})