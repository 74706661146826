
//
var config = {//测试站
    "base_url": "https://demo-free.kaidianxing.com/api",
    "attachment_url": "https://demo-free.kaidianxing.com/data/attachment/",
    "public_url": "https://demo-free.kaidianxing.com/",
    "wap_dist_url": "https://demo-free.kaidianxing.com/static/dist/shop/kdx_wap/",
    "route": "history"
}
// var config = { //lkx
//     "base_url": "http://likexin.shopstar.cn/api",
//     "attachment_url": "http://likexin.shopstar.cn/data/attachment/",
//     "public_url": "http://likexin.shopstar.cn/",
//     "wap_dist_url": "http://likexin.shopstar.cn/static/dist/shop/kdx_wap/",
//     "route": "history"
// }
// var config = {
// 	base_url: 'http://yuning-free.kdxdev.com/api',
// 	attachment_url: 'http://yuning-free.kdxdev.com/data/attachment/',
// 	public_url: 'http://yuning-free.kdxdev.com/',
// 	wap_dist_url: 'http://yuning-free.kdxdev.com/static/dist/shop/kdx_wap/',
// 	route: 'history',
// };
try {
	if (window) {
		window.config = window.config
			? Object.assign(config, window.config)
			: config;
		config = window.config;
	}
} catch (e) {
	console.log(e);
}

try {
	exports.config = config;
} catch (e) {
	console.log(e);
}
