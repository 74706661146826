

let afterLocation = (item) => ({
    type: [{
        required: true,
        message: '请选择推送方式',
        trigger: 'blur'
    }, ],
    thumb: [{
        validator: (rule, value, callback) => {
            if (!item.params.thumb&&item.params.type==1) {
                callback(new Error('请选择推送封面'));
            } else {
                callback();
            }
        },
        trigger: 'blur'
    }],
    title: [{
        required: true,
        message: '请输入推送标题',
        trigger: 'blur'
    }],
    content: [{
        required: true,
        message: '请输入推送描述',
        trigger: 'blur'
    }],
    linkurl: [{
        required: true,
        message: '请选择推送链接',
        trigger: 'blur'
    }],
})
export default (item) => ({
    afterLocation: afterLocation(item),
    beforeLocation(data) {
        let flag = !data.params.title || !data.params.content
        if(data.params.type =='1'){
            flag = flag && !data.params.linkurl
        }
        
        if(flag){
            return new Error('请完善推送设置')
        }
       
        if(!data.params.thumb&&data.params.type==1){
            return new Error('请选择推送封面')
        }
    }
})