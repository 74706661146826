
/**
 * 首页的装修项
 */
export default {
    base: [ //基础组件
        {
            type: 'poster_bgimg',
            name: '背景图片',
        }, {
            type: 'poster_qrcode',
            name: '二维码',
        }, {
            type: 'poster_shoplogo',
            name: '店铺LOGO',
            icon: 'iconfont icon-haibao-logo'
        },
        {
            type: 'poster_shopname',
            name: '店铺名称',
            icon: 'iconfont icon-haibao-dianpumingcheng'
        },  {
            type: 'poster_avatar',
            name: '头像',
            icon: 'iconfont icon-shuju-huiyuanshu'
        },
        {
            type: 'poster_nickname',
            name: '昵称',
            icon: 'iconfont icon-haibao-yonghunicheng'
        }, {
            type: 'poster_img',
            name: '图片',
            icon: 'iconfont icon-datuzu'
        }, {
            type: 'poster_text',
            name: '文本',
            icon: 'iconfont icon-biaotilan'
        },  {
            type: 'poster',
            name: '商品海报',
            show: false,
            icon: 'iconfont icon-zujian-yemianshezhi'
        },
    ]
}