
/*
 * @Description: api 地址过滤器
 * @Autor: ding.luchao
 */
import store from '@/store'
/**
 * 责任链过滤所有的内容。
 * 注册中心
 */
const Responsibility = [
  (options = {})=> {
    if(!options.baseURL.includes('manageApi')&& options.url.includes('adminApi')) {
      options.baseURL = `${location.origin}/${store.state.config.admin?.enter}/api`
      options.url = options.url.replace('adminApi','')
      return false
    } else if(options.url.includes('adminApi')) {
      options.baseURL = '/'
      return false
    }
  },
  // 过滤admin
  (options = {}) => {
    if (!options.baseURL.includes("manageApi")) {
      options.url = options.url.replace(/\/?manage\//, "/");
    }
  },
];

export default (options = {}) => {
  for (let i = 0; i < Responsibility.length; i++) {
    if(Responsibility[i](options)== false) {
      break
    }
  }
};
