
let afterLocation = item => ({
    data: [{
        validator: (rule, value, callback) => {
            if (item.params.goodsdata == 1&&!item.params.cateid) { //选择分类
                callback(new Error('请添加商品分类')) 
            } else if (item.params.goodsdata == 2&&!item.params.groupid) { //选择分组
                callback(new Error('请添加商品分组'))
            } else if (!item.data.length) {
                callback(new Error('请选择商品'));
            } else {
                callback();
            }
        },
        trigger: 'blur'
    }],
})
export default (item) => ({
    afterLocation: afterLocation(item), //定位后的校验
    beforeLocation(data) { //定位前的校验
        if(data.params.componentbg == '2' && !data.params.bgimg) {
            return new Error('请选择背景图片')
        }
        if (data.params.goodsdata == 1) { //选择分类
            if (!data.params.cateid) {
                return new Error('请添加商品分类')
            }
        } else if (data.params.goodsdata == 2) { //选择分组
            if (!data.params.groupid) {
                return new Error('请添加商品分组')
            }
        } else if (data) {
            if (!data.data.length) {
                return new Error('请添加商品')
            }
        }
    },

})