
/**
 * 首页的装修项
 */
export default {
    global: [//全局组件
        {
            type: 'diymenu',
            name: '自定义菜单',
            svg: 'bottom-menu'
        }
    ],
}