

let afterLocation = item=>({
    data: [{
        validator: (rule, value, callback) => {
            if (!item.data.length) {
                callback(new Error('请添加优惠券'));
            } else {
                callback();
            }
        },
        trigger: 'blur'
    }],
})
export default item => ({
    afterLocation:afterLocation(item), //定位后的校验
    beforeLocation(data) { //定位前的校验
        if (data) {
            if (!data.data.length) {
                return new Error('请添加优惠券')
            }
        }
    },
    
})