

let afterLocation = (item) => ({

    content: [{
        required: true,
        // message: '请输入文本',
        validator: (rule, value, callback) => {
            if (!item.params.content) {
                callback(new Error('请输入文本'))
            } else {
                callback()
            }
        },
        // trigger: 'blur'
    },],
})

export default (item) => ({
    afterLocation: afterLocation(item),
    beforeLocation(data) {
        if(!data.params.content){
            return new Error('请输入文本')
        }
    }
})
