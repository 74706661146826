
let afterLocation = (item) => ({
    customerlink:  [{
        validator: (rule, value, callback) => {
            if ((item.params.hidecart == "-4" || item.params.hidelike == "-4" || item.params.hideshop == "-4") && !item.params.customerlink) {
                callback(new Error('请选择客服'))
            } else {
                callback();
            }
        },
        trigger: 'blur'
    }],
})
export default (item) => ({
    afterLocation: afterLocation(item),
    beforeLocation(data) {
        if(data) {
            if ((data.params.hidecart == "-4" || data.params.hidelike == "-4" || data.params.hideshop == "-4") && !data.params.customerlink) {
                return new Error('请选择客服')
            }
        }
    }
})