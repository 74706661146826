import { render, staticRenderFns } from "./kdxModalFrame.g.vue?vue&type=template&id=9a4dde8e&scoped=true"
import script from "./kdxModalFrame.g.vue?vue&type=script&lang=js"
export * from "./kdxModalFrame.g.vue?vue&type=script&lang=js"
import style0 from "./kdxModalFrame.g.vue?vue&type=style&index=0&id=9a4dde8e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a4dde8e",
  null
  
)

export default component.exports