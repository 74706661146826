
<template>
    <div class="status-text" @click="handleClick">
        <span :class="[`status-text-${type}`, `status-text-size-${size}`]">
            <slot></slot>
        </span>
    </div>
</template>

<script>
    // 用于一些状态字显示
    export default {
        name: "kdxStatusText",
        props: {
            type: {
                type: String,
                default: 'default',
                validate: ['info', 'success', 'warning', 'danger', 'disabled']
            },
            size: {
                type: String,
                default: 'default',
                validate: ['default', 'large', 'small', '24-34']
            }
        },
        methods: {
            handleClick(e) {
                this.$emit('click', e);
            }
        }
    };
</script>

<style scoped lang="scss">
    .status-text {
        display: inline-block;
        // default
        .status-text-default {
            color: $text-first;
        }
        .status-text-info {
            color: $brand-color;
        }
        .status-text-success {
            color: $success-color;
        }
        .status-text-warning {
            color: $warning-color;
        }
        .status-text-danger {
            color: $danger-color;
        }
        .status-text-disabled {
            color: $text-third;
        }
        .status-text-size-default {
            @include font-14-20;
        }
        .status-text-size-large {
            @include font-18-25-bold;
        }
        .status-text-size-small {
            @include font-12-16;
        }
        .status-text-size-24-34 {
            @include font-24-34-bold;
        }
    }
</style>
