
export default ()=>({
    beforeLocation(data) {
        let err = false;
        if (data.params.datatype == '0') {
            let start = (data.params.starttime + '').trim();
            let end = (data.params.endtime + '').trim();
            if (!(/^\d+$/.test(start) && /^\d+$/.test(end))) {
                return new Error('请填写随机时间区间')
            }
        } else if (data.params.datatype == '2') {
            for (let item of data.data) {
                if (!item.imgurl) {
                    err = new Error('请选择图片')
                } else if (!item.nickname) {
                    err = new Error('请填写昵称')
                } else if (!item.time) {
                    err = new Error('请填写下单时间')
                }

                if (err) {
                    return err
                }
            }
        }


    }
})