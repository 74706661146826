
/**
 * 根据store获取用户信息
 */
import store from '@/store';
export const getUserInfo = () => {
    // const shopType = store.state?.account?.shop?.shopType
    return {
        'Client-Type': 50,
        'Session-Id': store.state?.account?.sessionId,
    }
}