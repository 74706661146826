
/* eslint-disable */
let paths = require.context('./', false, /\/.*\.js$/); //获取当前全部路由
let modules = {}; //各个模块的集合
let moduleName = [];
paths.keys().map(item => { //获取本级目录下的全部配置参数 
    if (item != './index.js') {
        moduleName.push(item.replace(/(\.\/|\.js)/g, '').toLowerCase());
        return paths(item).default;
    }
    return '';
}).filter(item => item).forEach((item, index) => {
    modules[moduleName[index]] = item;
});

export function getValidator(item) {
    if(!item){
        return {}
    }
    let id=(item.id||item.type).toLowerCase();
    let checker=modules[id];
    if(typeof checker=='function'){
        checker=checker(item)
    }
    return checker ?.afterLocation || {};
}

export function checkForm(list,$route,$store) {
    let item = null;
    let allRight = true
    let res = null;
    return new Promise((resolve, reject) => {
        let allList=[...list]
        for(let k in $store.state.asPageInfo){
            allList.push($store.state.asPageInfo[k])
        }
        for (let k in allList) {
            item = allList[k];
            
            let id=item.id.toLowerCase()
            checker=modules[id]
            if(typeof checker=='function'){
                checker=checker(item,$route,$store)
            }
            fun = checker?.beforeLocation;

            if (typeof fun == 'function') {
                let res = fun(item,$route,$store);
               
                if (res instanceof Error) {
                    allRight = false;
                    reject({ errorMessage: res, item: item });
                    return
                } 
            }
        }
        if (allRight) {
            resolve()
        }
    })
}