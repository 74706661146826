
let afterLocation = () => ({
   flowId:  [{
       required: true,
       message: '广告ID必填',
       trigger: 'blur'
   }],
})
export default () => ({
    afterLocation: afterLocation(),
    beforeLocation(data) {
        if(data) {
            if (!data.params.flowId) {
                return new Error('广告ID必填')
            }
        }
    }
})