
<template>
    <div class="banner_qr_code">
        <div class="banner">
            <img v-if="type === 'img'" ref="qrcode" :src="imgUrl" alt="">
            <canvas v-else class="qrcode" ref="qrcode"></canvas>
        </div>
    </div>
</template>

<script>
    import Qrious from 'qrious';

    export default {
        name: "QrCode",
        props: {
            // 商品路径
            url: {
                type: String,
                required: true
            },
            // 二维码背景色
            backgroundColor: {
                type: String,
                default: '#fff'
            },
            // 二维码背景透明度
            backgroundAlpha: {
                type: Number,
                default: 0.8
            },
            // 二维码颜色
            foregroundColor: {
                type: String,
                default: '#000'
            },
            // 二维码透明度
            foregroundAlpha: {
                type: Number,
                default: 0.8
            },
            // 二维码纠错等级
            level: {
                type: String,
                default: 'H',
                validator(value) {
                    return ['L', 'M', 'Q', 'H'].indexOf(value) !== -1;
                }
            },
            // 二维码大小(像素)
            size: {
                type: Number,
                default: 100
            },
            type: { // 类型  img
                type: String,
                default: ''
            }
        },
        data() {
            return {
                imgUrl: ''
            }
        },
        watch: {
          url: {
              handler() {
                 this.url && this.markQrcode();
              },
              immediate: true
          },
            size: {
              handler() {
                  this.url && this.markQrcode();
              },
                immediate: true
            }
        },
        methods: {
            // 生成二维码
            markQrcode() {
                const element = this.$refs.qrcode;
                let qr = new Qrious({
                    element,
                    value: this.url,
                    background: this.backgroundColor,
                    backgroundAlpha: this.backgroundAlpha,
                    foreground: this.foregroundColor,
                    foregroundAlpha: this.foregroundAlpha,
                    level: this.level,
                    size: this.size,
                });
                this.imgUrl = qr.toDataURL()
            }
        },
        mounted() {
           this.url && this.markQrcode();
        }
    };
</script>

<style scoped lang="scss">
    .banner_qr_code {

    }
</style>
