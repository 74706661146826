
import {replaceImage} from '@/components/decorate/decorate-assets/helpers'
import { mapGetters } from 'vuex'
// import Permission from '@/components/decorate/decorate-helpers/permission'
export default {
    computed: {
        ...mapGetters('config',{
            $getPermMap:'getPermMap',
        }),
    },
    methods: {
        replaceImage,
        $finishing() {
            this.$Message['error']({
                background: true,
                content: '未完代续'
            });
        }
    }
}
