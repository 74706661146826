
window.cssThemeVar={
    '--theme-color': '#1F65D7',
    '--theme-submenuBackground':'#E2EBFB',
    '--theme-navBackground':'#1F65D7',
    '--decorate-color':'#2d8cf0'
}     
for(var k in  window.cssThemeVar){
    document.documentElement.style.setProperty(k, window.cssThemeVar[k])
}
exports.cssThemeVar= window.cssThemeVar