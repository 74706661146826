

function dealPoint(x, y) {
    if (!x || !y) return
    //  取出起点与结束点坐标
    let [startX, startY] = x.split(',');
    let [endX, endY] = y.split(',');

    // 取出最小坐标(sx,sy)最大坐标(ex,ey)
    let sx, sy, ex, ey;
    sx = Math.min(startX, endX)
    ex = Math.max(startX, endX)
    sy = Math.min(startY, endY)
    ey = Math.max(startY, endY)

    let arr = [];

    for (let sx1 = sx; sx1 <= ex; sx1++) {
        for (let sy1 = sy; sy1 <= ey; sy1++) {
            let point = `${sx1},${sy1}`
            arr.push(point)
        }
    }

    // 存在选中坐标点返回上一次选中
    return arr;
}


let afterLocation = item => ({
    data: [{
        validator: (rule, value, callback) => {
            if (item.params.cubestyle !== 'custom') {
                if (item.data.some(v => !v.imgurl)) {
                    callback(new Error('请添加图片'))
                } else {
                    callback()
                }
            } else {
                let arr = []
                item.data.forEach(v => {
                    arr = arr.concat(dealPoint(v.startP, v.endP))
                })

                if (arr.length !== Math.pow(item.params.cubenum, 2)) {
                    callback(new Error('请填充满魔方列'))
                } else if (item.data.some(v => !v.imgurl)) {
                    callback(new Error('请添加图片'));
                } else {
                    callback()
                }
            }
        },
        trigger: 'blur'
    }],
})
export default item => ({
    afterLocation: afterLocation(item), //定位后的校验
    beforeLocation(data) { //定位前的校验
        if (data.params.cubestyle !== 'custom') {
            if (data.data.some(v => !v.imgurl)) {
                return new Error('请添加图片')
            }
        } else {
            let arr = []
            data.data.forEach(v => {
                arr = arr.concat(dealPoint(v.startP, v.endP))
            })

            if (arr.length !== Math.pow(data.params.cubenum, 2)) {
                return new Error('请填充满魔方列')
            } else if (data.data.some(v => !v.imgurl)) {
                return new Error('请添加图片');
            }
        }


    },

})