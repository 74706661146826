
/* eslint-disable */
export function getSystemInfo() {
    new Promise((resolve, reject) => {
        if (uni) {
            uni.getSystemInfo({
                success: function (res) {
                    resolve(res)
                }
            });
        } else {
            resolve({
                pixelRatio: window.devicePixelRatio
            })

        }
    })

}

function isApp() {//判断小程序类型
    var userAgent = navigator.userAgent;
    if (userAgent.indexOf('AlipayClient') > -1) {// 支付宝小程序的 
        return 'aliApp'
    } else if (/QQ/i.test(userAgent) && /miniProgram/i.test(userAgent)) {// QQ 小程序
        return 'qqApp'
    } else if (/miniProgram/i.test(userAgent)) {// 微信小程序
        return 'wxApp'
    } else if (/toutiaomicroapp/i.test(userAgent)) {// 头条小程序
        return 'ttApp'
    } else if (/swan/i.test(userAgent)) {// 百度小程序
        return 'swanApp'
    }
}

function isH5() {//判断移动端或PC端
    var is_mobi = navigator.userAgent.toLowerCase().match(/(ipod|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i) != null;
    if (is_mobi) {
        return 'isMobile'
    } else {
        return 'isPC'
    }
}

export function px2rpx(px, rate=1, defVal=0) {
    if(px===undefined||px===''){
        px=defVal
    }
    if(!px){
        px=0
    }
    return px / 2 * rate + 'px'
}