
let afterLocation = item => ({
    data: [{
        validator: (rule, value, callback) => {
            let errGood = null;
            let error= item.data.some(v => {
                if (!v.goods.data.length) {
                    errGood=v
                }
                return !v.goods.data.length
            })
            if (!item.data.length) {
                callback(new Error('请添加标签页'));
            } else if (error) {
                callback(new Error(errGood.title + '请添加商品'))
            } else {
                callback();
            }
        }
    }],
})
export default item => ({
    afterLocation: afterLocation(item), //定位后的校验
    beforeLocation(data) { //定位前的校验
        let errGood = null;
        let error= data.data.some(v => {
            if (!v.goods.data.length) {
                errGood=v
            }
            return !v.goods.data.length
        })
        if (data) {
            if (!data.data.length) {
                return new Error('请添加标签页')
            } else if (error) {
                return new Error(errGood.title + '请添加商品')
            }
        }
    },

})