
<template>
    <div class="hint-text">
        <span class='content' v-html='content'></span>
        <span>
            <slot></slot>
        </span>
        <router-link v-if="path" class="router" :to="path">{{routeContent}}</router-link>
        <span v-if="!path" class="router">{{routeContent}}</span>
        <span class="error vip-error" v-show="errContent">{{errContent}}</span>
    </div>
</template>

<script>
    // 下方说明文字
    export default {
        name: "kdxHintText",
        props: {
            content: {
                type: String
            },
            routeContent: {
                type: String
            },
            path: {
                type: String
            },
            errContent: {
                type: String
            }
        }
    };
</script>

<style scoped lang="scss">
    .hint-text {
        @include font-12-16;
        padding-top:10px;
        color: $text-third;
        /deep/ .ivu-btn {
            font-size: 12px;
        }
        a {
            color: $brand-color;
            &.ivu-btn {
                height: inherit;
                vertical-align: inherit;
            }
        }
        .content {
            font-size: 12px;
            line-height: 16px;
            color: $text-third;
            padding-top: 6px;
        }
        .router {
            color: $brand-color;
        }
        .error {
            color: #FF1F2C;
            font-weight: bold;
            font-size: 12px;
            line-height: 17px;
        }
        /*.vip-error {*/
        /*    font-size: 14px;*/
        /*}*/
    }
</style>
