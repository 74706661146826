import Vue from 'vue'
import 'ant-design-vue/dist/antd.css'
// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import Antd from 'ant-design-vue'
import '@/styles/index.scss' // global css
// pch5
// import './pch5/js/jquery.min'
// import './pch5/js/microdone'
import './pch5/css/microdone.css'

// echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
// Vue.use(ElementUI, { zIndex: 3000 })
Vue.use(Antd)
