
<template>
    <div class="content-bar">
        <div class="content" ref="content_bar" v-error-box='"kdxContentBar"' v-loading="loading">
            <slot></slot>
            <div class="copyright">
                <p><a href="https://www.kaidianxing.com" target="_blank">青岛开店星信息技术有限公司提供技术支持</a></p>
                <p v-if="baseSetting.icp_code"><a href="https://beian.miit.gov.cn"
                                                  target="_blank">
                    <br/>
                    网站备案号:{{ baseSetting.icp_code || '' }}</a></p>
            </div>
        </div>
        <div class="footer-btn" v-if="$slots.btn">
            <slot name="btn"></slot>
        </div>
    </div>
</template>

<script>
import {
    mapState
} from 'vuex';

export default {
    name: "kdxContentBar",
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState('menu', {
            openSubmenu: state => state.openSubmenu
        }),
        ...mapState('config', {
            baseSetting: state => state.baseSetting
        }),
    },
    methods: {
        // 返回顶部
        returnToTop(top = 0) {
            this.$refs['content_bar']?.scrollTop && (this.$refs['content_bar'].scrollTop = top);
        },
        handleScroll(callback) {
            this.$refs['content_bar'].addEventListener('scroll', e => {
                if (typeof callback === 'function') {
                    callback(e);
                }
            })
        }
    },
    mounted() {
        this.handleScroll();
    }
};
</script>

<style scoped lang="scss">
.content-bar {
    width: 100%;
    /*min-width: 1336px;*/
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    > .content {
        flex: 1;
        overflow-y: auto;
        position: relative;
        border-radius: 2px;

        .copyright {
            width: 100%;
            /*padding: 20px 0;*/
            padding: 20px 0;
            text-align: center;
            @include font-12-16;
            color: $text-zhushi;

            /deep/ a {
                color: $text-zhushi;
            }
        }
    }

    > .footer-btn {
        flex: 0;
        border-top: 1px solid $border-color;
        padding: 16px 0 16px 160px; // border-top: 1px solid $border-color;
        box-sizing: border-box;
        bottom: 0;
        /*width: 100%;*/
        border-radius: 2px;
        background-color: #FFFFFF;
        z-index: 10;

        > .ivu-btn {
            margin-right: 20px;
        }
    }
}
</style>
