import api from "@/components/decorate/decorate-api";
export default {
  namespaced: true,
  state: {
    systemSetting: null,
    wap_url: "",
    randomGoodId: "",
    history: {},
  },
  mutations: {
    setHistory(state, data) {
      state.history = data;
    },
    setRanGoodId(state, id) {
      state.randomGoodId = id;
    },
    setWapUrl(state, wap_url) {
      state.wap_url = wap_url;
    },
    setSystemSetting(state, res) {
      state.systemSetting = res;
    },
  },
  actions: {
    getSysSetting(ctx) {
      return new Promise((resolve, reject) => {
        if (ctx.state.systemSetting) {
          resolve(ctx.state.systemSetting);
          return;
        }
        const res = {
            error: 0,
            mall_status: "1",
            name: "商城名称",
            agreement_name: "",
            agreement_content: "用户注册使用协议",
            icp_code: "鲁ICP备2021028233号-1",
            global_code: "",
            mall_close_url: "",
            logo: "image/2022/11/6e295a6814f71f865976946082c5d37f.jpg",
            login_show_img:
              "image/2022/08/ffb34c5f0f86a62c49b5e1f6fff4da34.png",
            description: "11",
            sale_out: "",
            loading: "",
            photo_preview: "0",
          }
        ctx.commit("setSystemSetting", {
          basic: {
            ...res
          },
        });
        resolve(res);
      });
    },
  },
};
