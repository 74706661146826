
let afterLocation = (item) => ({
    link_url:  [{
        validator: (rule, value, callback) => {
            if (item.params.customer == "rr" && !item.params.link_url) { 
                callback(new Error('请选择客服')) 
            } else {
                callback();
            }
        },
        trigger: 'blur'
    }],
})
export default (item) => ({
    afterLocation: afterLocation(item),
    beforeLocation(data) {
        if(data) {
            if (data.params.customer == "rr" && !data.params.link_url) {
                return new Error('请选择客服')
            }
        }
    }
})