
/**
 * 实现类似axios的请求方法
 */
import axios from 'axios'
import { config as settings } from './settings.js'
import qs from 'qs'
import flatten from '../../decorate-assets/jsTools/flatter.js'
import Responsibility from "./filter/api.filter";
import getSessionId from './getSessionId.js'

let base_url = settings.base_url

// 解决跨域使用
base_url = process.env.NODE_ENV === 'development' ? '/manageApi/' : settings.base_url

let http = axios.create({
    baseURL: base_url,
    withCredentials: true,
    transformRequest: [
        function(data) {
            // 解决传递数组变成对象的问题
            data = flatten(data) //对象扁平化
            Object.keys(data).forEach((key) => {
                if (typeof data[key] === 'object') {
                    data[key] = JSON.stringify(data[key]) // 这里必须使用内置JSON对象转换
                }
            })
            data = qs.stringify(data) // 这里必须使用qs库进行转换
            return data
        },
    ],
})
// 请求拦截
//注意请求头中不能有null或undefined的存在，不然会报错
http.interceptors.request.use(
    (config) => {
        return new Promise((r) => {
            if (
              !config.url.includes("get-session-id")
            ) {
                getSessionId().then((res) => {
                    config.headers["Session-Id"] = res;
                r(config);
              });
            } else {
              r(config);
            }
        })
    },
    (error) => {
        return Promise.reject(error)
    }
)
http.interceptors.request.use(
    (config) => {
        Responsibility(config);
        return config
    },
    (err) => {
        //请求失败
        console.error('请求失败')
        return Promise.reject(err)
    }
)

// 响应拦截
http.interceptors.response.use(
    (response) => {
        // if (response.headers['content-type'].indexOf('text/html') > -1) {
        //     window.location.replace(settings.base_url)
        // }
        // 返回进入拦截成功
        return Promise.resolve(response.data)
    },
    function(error) {
        // 返回进入拦截失败
        return Promise.reject(error)
    }
)

export default http
