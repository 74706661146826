/**
 * 首页的装修项
 */
export default {
  base: [
    //基础组件
    {
      type: "banner",
      name: "轮播图",
      svg: "tupianlunbo",
    },
    {
      type: "cube",
      name: "图片魔方",
      svg: "cube",
    },
    {
      type: "menu",
      name: "分类导航",
      svg: "anniuzu",
    },
    {
      type: "goods",
      name: "热销商品",
      icon: "iconfont icon-shangpinzu",
    },
    // {
    //   type: "title",
    //   name: "标题栏",
    //   icon: "ivu-icon ivu-icon-ios-contract",
    // },
    // {
    //   type: "search",
    //   name: "搜索框",
    //   svg: "sousuo1",
    // },

    // {
    //   type: "picture",
    //   name: "单图组",
    //   icon: "iconfont icon-dantuzu",
    // },

    // {
    //   type: "blank",
    //   name: "辅助空白",
    //   svg: "fuzhukongbai",
    // },
    // {
    //   type: "line",
    //   name: "辅助线",
    //   icon: "ivu-icon ivu-icon-ios-crop",
    // },
    // {
    //   type: "tabbar",
    //   name: "标签页",
    //   svg: "xuanxiangka",
    // },
    // {
    //   type: "topmenu",
    //   name: "顶部菜单",
    //   svg: "dingbudaohang",
    // },
    // {
    //   type: "fixedsearch",
    //   name: "固定搜索框",
    //   icon: "ivu-icon ivu-icon-md-qr-scanner",
    // },
    // {
    //   type: "video",
    //   name: "视频",
    //   svg: "shipinzu",
    // },
    // {
    //   type: "pictures",
    //   name: "图片展播",
    //   svg: "tupianzhanbo",
    // },
    // {
    //   type: "richtext",
    //   name: "富文本",
    //   svg: "fuwenben",
    // },

    // {
    //   type: "customer",
    //   name: "客服",
    //   svg: "customer",
    // },
  ],
//   market: [
//     //营销组件
//     {
//       type: "coupon",
//       name: "优惠券",
//       icon: "ivu-icon ivu-icon-md-timer",
//     },
//     {
//       type: "notice",
//       name: "公告",
//       icon: "ivu-icon ivu-icon-logo-twitch",
//     },
//     {
//       type: "liver",
//       name: "小程序直播",
//       svg: "liver",
//     },
//     {
//       type: "seckill",
//       name: "秒杀",
//       svg: "seckill",
//     },
//     {
//       type: "groups",
//       name: "拼团",
//       svg: "groups",
//     },
//     {
//       type: "wxTransaction",
//       name: "视频号直播",
//       icon: "iconfont icon-zhibo-mian",
//     },
//   ],
//   other: [
//     {
//       type: "gotop",
//       name: "返回顶部",
//       icon: "iconfont icon-fanhuidingbu",
//     },
//     {
//       type: "followbar",
//       name: "关注条",
//       icon: "iconfont icon-guanzhutiao",
//     },
//   ],
};
