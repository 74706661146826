export default {
  namespaced: true,
  state: {
    merchantId: '',
    applyNo: '',
    code: ''
  },
  mutations: {
    SAVE_MERCHANT_ID(state, val) {
      state.merchantId = val
    },
    UPDATE_APPLY_NO(state, val) {
      state.applyNo = val
    },
    UPDATE_CODE(state, val) {
      state.code = val
    }
  },
  actions: {},
  getters: {}
}
