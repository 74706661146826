import Vue from 'vue'
import App from './App.vue'
import { router } from './router/index'
import store from '@/store/index'
import registerGlobalComponents from './plugins/register-components'
import '@/styles/index.scss' // global css
import '@/styles/animate.min.css'
import '@/plugins/index.js'
import ElementUI from 'element-ui'
ElementUI.Dialog.props.closeOnClickModal.default = false
import 'element-ui/lib/theme-chalk/index.css'
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
Vue.use(ElementUI, { zIndex: 3000 })
registerGlobalComponents()
Vue.config.productionTip = false

// 保证组件css层级高于iview层级
import {Lazyload} from 'vant';
import mixins from '@/components/decorate/decorate-helpers/mixins.js';
Vue.mixin(mixins);
// options 为可选参数，无则不传
Vue.use(Lazyload, {
  lazyComponent: true,
  loading: '',
  error: ''
});
//全局自定义组件
let requireComponent = require.context('./components/decorate/decorate-components', true, /\/.*\.g\.vue$/);
requireComponent.keys().forEach(fileName => {
    // 获取组件配置
    const componentConfig = requireComponent(fileName);
    const component = componentConfig.default || componentConfig;
    // 组件注册,组件必须写name
    Vue.component(component.name, component);
});
// svg文件
const svgFile = require.context('./components/decorate/decorate-assets/svg', false, /\.svg$/);
const svgRequireAll = requireContent => requireContent.keys().map(requireContent);
svgRequireAll(svgFile);
import ViewUI from 'view-design';
import iViewPro from '@/components/decorate/decorate-libs/iview-pro/iview-pro.min.js';
import '@/components/decorate/decorate-assets/font/iconfont.js';
import '@vant/touch-emulator';
import 'view-design/dist/styles/iview.css'
import '@/components/decorate/decorate-libs/iview-pro/iview-pro.css';
import '@/components/decorate/decorate-assets/css/reset.scss';
import '@/components/decorate/decorate-assets/css/theme.css';
Vue.use(ViewUI, {
  transfer: true
});
Vue.use(iViewPro);
import '@/components/decorate/decorate-helpers/changeTheme.js'
import * as utils from '@/components/decorate/decorate-assets/helpers'
import {$focusError} from '@/components/decorate/decorate-helpers/directives.js'
import {media} from '@/components/decorate/decorate-assets/helpers'; // 图片文件地址
Vue.prototype.$utils = utils;
Vue.prototype.$css = window.cssThemeVar;
Vue.prototype.$isPC = true;
Vue.prototype.$media = media;
Vue.prototype.$focusError=$focusError;
Vue.prototype.$history=history
Vue.prototype.bus = new Vue()
Vue.prototype.process = { env: Object.assign({}, process.env) };
new Vue({
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this
  },
  router,
  store
}).$mount('#app')
