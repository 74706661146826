
let afterLocation = item => ({
    data: [{
        validator: (rule, value, callback) => {
            if (item.data.data.length == 0) { //选择分类
                callback(new Error('请选择活动商品')) 
            } else {
                callback();
            }
        },
        trigger: 'blur'
    }],
})
export default (item) => ({
    afterLocation: afterLocation(item), //定位后的校验
    beforeLocation(data) { //定位前的校验
        if(data.data.length == 0) {
            return new Error('请选择活动商品')
        }
        
    },

})