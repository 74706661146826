
<template>
    <div class="copy-text" @click.stop="handleCopy">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "kdxCopyText",
        props: {
            text: {
                type: [String, Number],
                default: '',
                required: true
            }
        },
        methods: {
            handleCopy() {
                this.$copyText(this.text).then(() => {
                    this.$Message.success('复制成功');
                }).catch(() => {
                    this.$Message.error('复制失败');
                });
            }
        }
    };
</script>

<style scoped lang="scss">
    .copy-text {
        display: inline-block;
    }
</style>
